exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-challenges-tsx": () => import("./../../../src/pages/challenges.tsx" /* webpackChunkName: "component---src-pages-challenges-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-hall-of-fame-tsx": () => import("./../../../src/pages/hall-of-fame.tsx" /* webpackChunkName: "component---src-pages-hall-of-fame-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mission-tsx": () => import("./../../../src/pages/mission.tsx" /* webpackChunkName: "component---src-pages-mission-tsx" */),
  "component---src-templates-challenge-tsx": () => import("./../../../src/templates/challenge.tsx" /* webpackChunkName: "component---src-templates-challenge-tsx" */),
  "component---src-templates-event-tsx": () => import("./../../../src/templates/event.tsx" /* webpackChunkName: "component---src-templates-event-tsx" */),
  "component---src-templates-good-address-tsx": () => import("./../../../src/templates/good-address.tsx" /* webpackChunkName: "component---src-templates-good-address-tsx" */),
  "component---src-templates-illustration-tsx": () => import("./../../../src/templates/illustration.tsx" /* webpackChunkName: "component---src-templates-illustration-tsx" */),
  "component---src-templates-portrait-tsx": () => import("./../../../src/templates/portrait.tsx" /* webpackChunkName: "component---src-templates-portrait-tsx" */)
}

